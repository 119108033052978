import { getStrapiArticleFromTitle } from "../nr-useStrapiArticles";
import { getStrapiSeoDataFromTitle } from "../nr-useStrapiArticlesSeoData";
import { useBlogAuthors, useRecentPosts } from '@helpers-blog';

// Attach excerpt to multiple posts
export function attachExcerptToPosts(posts) {
    posts.nodes
        ? posts.nodes.forEach((post) => {
            post['excerpt'] = getStrapiArticleFromTitle(post.title).excerpt;
        })
        : posts.forEach((post) => {
            post['excerpt'] = getStrapiArticleFromTitle(post.title).excerpt.data.excerpt;
        })

    return posts;
}

// Attach excerpt to single posts
export function attactExcerptToPost(post) {
    post['excerpt'] = getStrapiArticleFromTitle(post.title).excerpt;

    return post;
}

export function attachSeoDetails(article) {
    article['seo'] = getStrapiSeoDataFromTitle(article.title).seo
    return article
  }

// Attach categories to multiple posts
export function attachCategoriesToPosts(posts) {
    posts.nodes.forEach((post) => {
        post['categories'] = getStrapiArticleFromTitle(post.title).categories
    })

    return posts;
}

// Attach categories to single post
export function attachCategoriesToPost(post) {
    post['categories'] = getStrapiArticleFromTitle(post.title).categories

    return post;
}

// Attach author details to collection information
export function attachAuthorDetails(collectionInfo) {

    const authors = useBlogAuthors();

    const author = authors.find((author) => author.name === collectionInfo.name);

    collectionInfo['thumbnail'] = author?.thumbnail;
    collectionInfo['skills'] = author?.skills.length !== 0 ? author?.skills : null;

    return collectionInfo;
}

// Attach missing post details to posts
export function attachPostDetails(posts) {

    const detailedPosts = useRecentPosts();

    posts.nodes.forEach((post, index) => {
        return Object.assign(posts.nodes[index], detailedPosts.find((detailedPost) => detailedPost.title === post.title))
    })

    attachExcerptToPosts(posts)
    attachCategoriesToPosts(posts)
}