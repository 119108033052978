import React from 'react'
import { Layout, Stack, Main, PreFooter } from '@layout'
import PageTitle from '@components/PageTitle'
import Pagination from '@components/Pagination'
import CardList from '@components/CardList'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { attachPostDetails, attachAuthorDetails } from '@utils-blog'
import { readingTime } from 'reading-time-estimator'
const PageCollection = ({ data: { posts, collectionInfo }, ...props }) => {
  attachPostDetails(posts) // Attach missing post details
  attachAuthorDetails(collectionInfo)
  const slugPrefix = { author: 'author', categories: 'category', tags: 'tag' }

  posts.nodes.forEach(ele => {
    if (ele.body) {
      const text = ele.body.data.body.replace(/(<([^>]+)>)/gi, '')
      ele.readingTime = readingTime(text, 220)
    }
  })

  return (
    <Layout {...props}>
      <Seo
        title={collectionInfo.name}
        description={collectionInfo.description}
      
      />
      <Divider />
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header={collectionInfo.name}
          subheader={collectionInfo.title}
          running={collectionInfo.description}
          totalCount={posts.totalCount}
        />
      </Stack>
      <Divider />
      <Stack>
        <Main>
          {posts.nodes && (
            <CardList
              nodes={posts.nodes}
              callFrom='Articles'
              variant={['horizontal-md', 'vertical']}
              columns={[1, 2, 3, 3]}
              omitCategory={
                props.pageContext &&
                props.pageContext.collectionType === 'category'
              }
            />
          )}
        </Main>
      </Stack>
      <Divider />
      <PreFooter>
        <Pagination
          {...posts.pageInfo}
          {...collectionInfo}
          {...{
            slug: `${
              Object.keys(slugPrefix).includes(props.pageContext.collectionType)
                ? `/${slugPrefix[props.pageContext.collectionType]}/`
                : ''
            }${collectionInfo.slug}/`
          }}
        />
      </PreFooter>
    </Layout>
  )
}

export default PageCollection
